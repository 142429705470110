<template>
  <div class="examination_management_edit" style="padding-bottom: 20px;">
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="msFormRules" label-position="right" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top" />
      <div>
        <div class="edit-row">
          <el-form-item label-width="110px" label="考试名称：" prop="examName">
            <el-input style="width:537px !important" class="zwx-input" v-model="msForm.examName" placeholder="请输入考试名称" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="110px" label="考试时长：" prop="examTime">
            <amount-input :width="'80px'" v-model="msForm.examTime" maxlength="9" if-unit unit-text="分钟" :numbers="9" :digits="0" clearable></amount-input>
          </el-form-item>
          <el-form-item label-width="110px" label="考试总分：" prop="totalScore">
            <amount-input :width="'80px'" v-model="msForm.totalScore" maxlength="9" disabled if-unit unit-text="分" :numbers="3" :digits="1" clearable></amount-input>
          </el-form-item>
          <el-form-item label-width="110px" label="合格分数：" prop="qualifiedScore">
            <amount-input :width="'80px'" v-model="msForm.qualifiedScore" maxlength="9" if-unit unit-text="分" :numbers="3" :digits="1" clearable></amount-input>
          </el-form-item>
        </div>
        <div v-if="showFiled" class="edit-row">
          <el-form-item label="考试类型：" label-width="110px" prop="examType">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.examType">
              <el-radio v-for="item in examTypeList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-if="showFiled" class="edit-row">
          <el-form-item label="学员类型：" label-width="110px" prop="roleType">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.roleType" @change="roleTypeChange">
              <el-radio v-for="item in traineeTypeList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-if="showFiled && msForm.roleType" class="edit-row">
          <el-form-item label="培训类型：" label-width="110px" prop="trainType">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.trainType">
              <el-radio v-for="item in trainTypeList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
         <div v-if="showFiled && ifShowOff" class="edit-row">
          <el-form-item label="考试须知标题：" label-width="110px" prop="examNoticeTitle">
            <el-input style="width:537px !important" class="zwx-input" v-model="msForm.examNoticeTitle" placeholder="请输入考试须知标题" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div v-if="showFiled && ifShowOff" class="edit-row">
          <el-form-item label="考试须知内容：" label-width="110px" prop="examNoticeDetails">
            <kind-editor id="editor_id" height="500px" width="800px" :imageSizeInfo="kindEditorParam.imageSizeInfo" :imageSize="kindEditorParam.imageSize" v-model="msForm.examNoticeDetails" @on-content-change="onContentChange"></kind-editor>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label="分配方式：" label-width="110px" prop="scoreMode">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.scoreMode" @change="scoreModeChange">
              <el-radio class="zwx-radio" :label="0">手动选择</el-radio>
              <el-radio class="zwx-radio" :label="1">随机抽取</el-radio>
              <el-radio class="zwx-radio" :label="2">规则抽取</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="抽取规则：" label-width="90px" prop="topicMode" v-if="msForm.scoreMode == 2">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.topicMode" @change="topicModeChange">
              <el-radio class="zwx-radio" :label="1">按类别</el-radio>
              <el-radio class="zwx-radio" :label="2">按题型</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- 平均分配时显示 题目类别 题目总数 -->
        <div class="edit-row" v-if="msForm.scoreMode == 1">
          <el-form-item label-width="110px" label="题目总数：" prop="topicAmount">
            <el-input style="width:100px !important" class="zwx-input" v-model="msForm.topicAmount" placeholder="请输入" clearable maxlength="9" @input="$validate.number($data['msForm'], 'topicAmount', 9, 0, 0)" />
          </el-form-item>
          <el-form-item label-width="90px" label="每题分值：" prop="topicScore">
            <amount-input :width="'80px'" v-model="msForm.topicScore" maxlength="9" :disabled="msForm.scoreMode != 1" if-unit unit-text="分" :numbers="3" :digits="1" clearable></amount-input>
          </el-form-item>
        </div>
        <div class="edit-row" v-if="msForm.scoreMode == 1">
          <el-form-item label-width="110px" label="题目类别：" prop="pointType" v-if="msForm.scoreMode == 1">
            <cascader-options-multi ref="mscCascader" v-model="msForm.pointType" :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" codeLevelNo="codeLevelNo" width="537px" :showFullTag="false" :collapsetags="true" v-on:propEvent="propEvent"></cascader-options-multi>
          </el-form-item>
        </div>
        <!--    手动选择    -->
        <base-headline title="考题配置" class="no-bottom" v-if="msForm.scoreMode == 0">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="showChooseTopicDialog()">添加</el-button>
        </base-headline>
        <el-table ref="chooseTopicMultipleTable" class="zwx-table" style="width: 100%;" row-key="rid" :data="msForm.choosedTopicList" v-if="msForm.scoreMode === 0" border stripe>
          <el-table-column width="130" prop="score" label="排序编号" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'choosedTopicList.' + scope.$index + '.orderIndex'" :rules="msFormRules.orderIndex">
                <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" clearable maxlength="3" placeholder="请输入" v-model.trim="scope.row.orderIndex" @change="orderIndexChange" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="600" prop="topicContent" label="题目标题" header-align="center" align="left"></el-table-column>
          <el-table-column width="100" prop="topicType" label="题型" header-align="center" align="center" :formatter="topicTypeFormatter"></el-table-column>
          <el-table-column width="130" prop="score" label="题目分值" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'choosedTopicList.' + scope.$index + '.score'" :rules="msFormRules.score">
                <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" clearable placeholder="请输入" v-model.trim="scope.row.score" @change="chooseTopicScoreChange" @input="$validate.number($data['msForm']['choosedTopicList'][scope.$index], 'score', 3, 1, 0)" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" :min-width="110" header-align="left" align="left">
            <template slot-scope="scope">
              <el-button class="zwx-button zwx-button-text-26 " type="danger" :class="'zwx-button-danger'" @click="deleteChoosedTopicList(scope.$index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <base-headline title="自定义配置" class="no-bottom" v-if="msForm.scoreMode == 2">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addCustomConfig()">添加</el-button>
        </base-headline>
        <el-table ref="multipleTable" class="zwx-table" style="width: 100%;" row-key="rid" :data="msForm.examConfigDetailExpList" v-if="msForm.scoreMode === 2" border stripe>
          <el-table-column width="250" prop="topicType" :label="msForm.topicMode == 1 ? '题目类别' : '题目类型'" header-align="center" align="left">
            <template slot-scope="scope">
              <el-form-item :prop="'examConfigDetailExpList.' + scope.$index + '.pointType'" :rules="msFormRules.pointType" v-if="msForm.topicMode == 1">
                <cascader-options-single ref="mscCascader" v-model="scope.row.pointType" :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" width="260px"></cascader-options-single>
              </el-form-item>
              <el-form-item :prop="'examConfigDetailExpList.' + scope.$index + '.topicType'" :rules="msFormRules.topicType" v-if="msForm.topicMode == 2">
                <cascader-options-single ref="mscCascader" v-model="scope.row.topicType" :options="topicTypeOptions" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" width="260px"></cascader-options-single>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="200" prop="topicAmount" label="题目数" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'examConfigDetailExpList.' + scope.$index + '.topicAmount'" :rules="msFormRules.topicAmount">
                <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" clearable placeholder="请输入" v-model.trim="scope.row.topicAmount" maxlength="11" @input="$validate.positiveInteger(scope.row, 'topicAmount')" @change="scoreChange" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="200" prop="topicScore" label="题目分值" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'examConfigDetailExpList.' + scope.$index + '.topicScore'" :rules="msFormRules.topicScore">
                <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" clearable placeholder="请输入" v-model.trim="scope.row.topicScore" @change="scoreChange" @input="$validate.number($data['msForm']['examConfigDetailExpList'][scope.$index], 'topicScore', 3, 1, 0)" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" :min-width="110" header-align="left" align="left">
            <template slot-scope="scope">
              <el-button class="zwx-button zwx-button-text-26 " type="danger" :class="'zwx-button-danger'" @click="deleteList(scope.$index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>

    <!-- 选择题目弹框 -->
    <base-dialog class="choose-topic-dialog" ref="chooseTopicDialogRef" title="选择题目" @determine="determineChooseTopicDialog" @cancel="cancelChooseTopicDialog" @close="closeChooseTopicDialog" :pagination="true" @currentPage="queryTopicList" :currentPage="chooseTopicForm.currentPage" :pageSize="chooseTopicForm.pageSize" :total="chooseTopicForm.total">
      <div class="choose-topic-body">
        <el-form class="zwx-form" key="chooseTopicForm" ref="chooseTopicFormRef" :model="chooseTopicForm" label-position="right" @submit.native.prevent>
          <div class="self-adapting-condition">
            <el-form-item label="题目标题：">
              <el-input style="width:310px !important" class="zwx-input" v-model.trim="chooseTopicForm.topicContent" placeholder="" clearable />
            </el-form-item>
            <el-form-item label="题目类别：" prop="pointTypes">
              <cascader-options-multi ref="mscCascader" v-model="chooseTopicForm.topicType" :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" codeLevelNo="codeLevelNo" width="315px" :showFullTag="false" :collapsetags="true" v-on:propEvent="propEvent"></cascader-options-multi>
            </el-form-item>
            <el-form-item label="题型：">
              <el-checkbox-group class="zwx-checkbox-group" v-model="chooseTopicForm.topicTypeList">
                <el-checkbox class="zwx-checkbox" :label="1">单选题</el-checkbox>
                <el-checkbox class="zwx-checkbox" :label="2">多选题</el-checkbox>
                <el-checkbox class="zwx-checkbox" :label="3">判断题</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-search" @click="queryTopicList(1)">检索</el-button>
          </div>
        </el-form>
        <!-- 列表展示部分 -->
        <el-table class="zwx-table choose-topic-table" row-key="rid" :data="chooseTopicForm.topicList" ref="topicMultipleTable" border stripe tooltip-effect="light" @selection-change="topicSelectionChange" :row-key="rowKey">
          <el-table-column key="selection" type="selection" width="55" header-align="center" align="center" :reserve-selection="true"></el-table-column>
          <el-table-column prop="pointTypeName" label="题目类别" width="200" header-align="center" align="left"></el-table-column>
          <el-table-column prop="topicContent" label="题目标题" header-align="center" align="left" />
          <el-table-column prop="topicType" label="类型" width="80" header-align="center" align="center" :formatter="topicTypeFormatter" />
        </el-table>
      </div>
    </base-dialog>

    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyExamConfig" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'ExaminationManagementEdit',
  components: {},
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      type: this.$route.params.type,
      /**
       * 富文本参数
       */
      kindEditorParam: {
        ifExpand: true,
        imageSizeInfo: '8M',
        imageSize: 8 * 1024 * 1024,
        examNoticeDetails: '',
      },
      //是否显示学员类型、培训类型
      showFiled: this.$route.meta.showFiled,
      ifShowOff: true,
      config: {},
      // 考试类型
      examTypeList: [],
      //获取的学员类型、培训类型层级数组
      dataList: [],
      // 学员类型数组
      traineeTypeList: [],
      //培训类型数组
      trainTypeList: [],
      pointTypeList: [], //培训类型平铺数据源
      simpleCodeList: [], // 题目类别层级
      msFormOld: null,
      topicTypeOptions: [
        {
          codeNo: '1',
          codeName: '单选题',
        },
        {
          codeNo: '2',
          codeName: '多选题',
        },
        {
          codeNo: '3',
          codeName: '判断题',
        },
      ],
      msForm: {
        rid: '',
        examName: '',
        examTime: '',
        totalScore: '',
        qualifiedScore: '',
        scoreMode: '',
        topicType: '',
        topicAmount: '',
        topicScore: '',
        topicMode: '',
        examConfigDetailExpList: [],
        choosedTopicList: [], //已选择题目
        examType: '', //考试类型
        roleType: '', //学员类型    默认数据源第一项
        trainType: '', //培训类型
        examNoticeDetails: '', //考试须知内容
        examNoticeTitle: '', //考试须知标题
      },
      msFormRules: {
        examName: [{ required: true, message: '考试名称不能为空', trigger: ['change', 'blur'] }],
        examTime: [{ required: true, validator: this.validaterExamTime, trigger: ['change', 'blur'] }],
        totalScore: [{ required: true, validator: this.validaterTotalScore, trigger: ['change', 'blur'] }],
        qualifiedScore: [{ required: true, validator: this.validaterQualifiedScore, trigger: ['change', 'blur'] }],
        scoreMode: [{ required: true, message: '分配方式不能为空', trigger: ['change', 'blur'] }],
        topicMode: [{ required: true, message: '抽取规则不能为空', trigger: ['change', 'blur'] }],
        topicAmount: [{ required: true, validator: this.validaterTopicAmount, trigger: ['change', 'blur'] }],
        topicType: [{ required: true, message: '题目类型不能为空', trigger: ['change', 'blur'] }],
        pointType: [{ required: true, message: '题目类别不能为空', trigger: ['change', 'blur'] }],
        topicScore: [{ required: true, validator: this.validaterTopicScore, trigger: ['change', 'blur'] }],
        topicContent: [{ required: true, message: '题目标题不能为空', trigger: ['change', 'blur'] }],
        orderIndex: [{ required: true, validator: this.validaterOrderIndex, trigger: ['change', 'blur'] }],
        examType: [{ required: false, message: '考试类型不能为空', trigger: ['change', 'blur'] }],
        roleType: [{ required: false, message: '学员类型不能为空', trigger: ['change', 'blur'] }],
        trainType: [{ required: false, message: '培训类型不能为空', trigger: ['change', 'blur'] }],
        examNoticeDetails: [{ required: false, message: '考试须知内容不能为空', trigger: ['change', 'blur'] }],
        examNoticeTitle: [{ required: false, message: '考试须知标题不能为空', trigger: ['change', 'blur'] }],
      },
      simpleCodeMap: {},
      //自定义选题
      chooseTopicForm: {
        currentPage: 1, // 页码
        pageSize: 18, // 条数
        total: 0, //总条数
        topicContent: '', // 题目标题，模糊查询
        pointTypes: '', // 题目类别
        topicTypeList: [], // 题目类型 1:单选 2:多选 3:判断题
        topicList: [],
        choosedList: [],
      },
    }
  },
  watch: {
    'msForm.topicAmount'(newVal, oldVal) {
      this.msForm.totalScore = (this.msForm.topicAmount * this.msForm.topicScore).toString()
    },
    'msForm.topicScore'(newVal, oldVal) {
      this.msForm.totalScore = (this.msForm.topicAmount * this.msForm.topicScore).toString()
    },
  },
  computed: {
    saveButtonDisabled() {
      var newJsonForm = JSON.stringify(this.msForm)
      if (newJsonForm != this.msFormOld) {
        return false
      } else {
        return true
      }
    },
  },
  created() {
    if (this.type === 'add') {
      this.msForm.scoreMode = 0
    }
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
    let config = window.top.vm.$store.state.config
    this.ifShowOff = config.ifTrainingExamSplit
  },
  mounted() {
    this.pageRouter()
    // 获取培训类型数据源
    this.getPointTypeList()
    if (this.showFiled) {
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002,5004', data => {
        this.dataList = this.manyListFul(data['5002'], '')
        this.examTypeList = data['5004']
        this.traineeTypeList = this.dataList.map(item => {
          return Object.assign(
            {},
            {
              codeName: item.codeName,
              codeNo: item.codeNo,
            }
          )
        })
        // 考试类型默认数据第一项
        this.msForm.examType = this.examTypeList.length > 0 && this.examTypeList[0].codeNo
        // 学员类别默认数据第一项
        this.msForm.roleType = this.traineeTypeList.length > 0 && this.traineeTypeList[0].codeNo
        // 培训类型默认是数据第一项的下级数据
        this.dataList.forEach(item => {
          if (this.msForm.roleType == item.codeNo) {
            this.trainTypeList = item.children
          }
        })
        this.msForm.trainType = this.trainTypeList[0].codeNo
      })
      this.msFormRules.examType[0].required = true
      this.msFormRules.roleType[0].required = true
      this.msFormRules.trainType[0].required = true
      this.msFormRules.examNoticeDetails[0].required = true
      this.msFormRules.examNoticeTitle[0].required = true
    }
  },

  methods: {
    // 富文本change事件
    onContentChange(val) {
      this.msForm.examNoticeDetails = val
      if (val) {
        this.$refs.msForm.validateField('examNoticeDetails', valid => {
          if (!valid) return
        })
      }
    },
    // 学员类型change事件
    roleTypeChange(val) {
      this.dataList.forEach(item => {
        if (val == item.codeNo) {
          this.trainTypeList = item.children
        }
      })
      this.msForm.trainType = this.trainTypeList[0].codeNo
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    rowKey(row) {
      return row.rid
    },
    // 抽取规则切换
    scoreModeChange(val) {
      this.msForm.totalScore = ''
      this.msForm.topicAmount = ''
      this.msForm.topicScore = ''
      this.msForm.topicType = ''
      this.msForm.examConfigDetailExpList = []
      this.msForm.choosedTopicList = []
      if ((this.type === 'add' && val == 2) || (this.type === 'edit' && val == 2 && this.$zwxBase.verifyIsBlank(this.msForm.topicMode))) {
        this.msForm.topicMode = 1
      }
      this.$nextTick(() => {
        this.$refs['msForm'].clearValidate()
      })
    },
    // 抽取规则切换
    topicModeChange(val) {
      this.msForm.totalScore = ''
      this.msForm.examConfigDetailExpList = []
      this.$nextTick(() => {
        this.$refs['msForm'].clearValidate()
      })
    },
    // 排序编号change事件
    orderIndexChange(e) {
      this.msForm.choosedTopicList.sort(function(a, b) {
        if (a.orderIndex < b.orderIndex) {
          return -1
        }
        if (a.orderIndex > b.orderIndex) {
          return 1
        }
        return 0
      })
    },
    // 手动选择分数change事件
    chooseTopicScoreChange() {
      let totalScore = 0
      this.msForm.choosedTopicList.forEach(item => {
        totalScore += item.score ? parseFloat(item.score) : 0
      })
      this.msForm.totalScore = totalScore + ''
    },
    // 规则抽取分数、题数change事件
    scoreChange() {
      let totalScore = 0
      this.msForm.examConfigDetailExpList.forEach(item => {
        if (this.$zwxBase.verifyIsNotBlank(item.topicAmount) && this.$zwxBase.verifyIsNotBlank(item.topicScore)) totalScore += item.topicAmount * item.topicScore
      })
      this.msForm.totalScore = totalScore + ''
    },
    validaterExamTime(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (parseInt(value) == 0) {
          return callback(new Error('考试时长应>0'))
        }
        return callback()
      } else {
        return callback(new Error('考试时长不能为空'))
      }
    },
    validaterTopicScore(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (parseFloat(value) == 0) {
          return callback(new Error('题目分值应>0'))
        }
        return callback()
      } else {
        return callback(new Error('题目分值不能为空'))
      }
    },
    validaterTotalScore(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (parseInt(value) == 0) {
          return callback(new Error('考试总分应>0'))
        }
        return callback()
      } else {
        return callback(new Error('考试总分不能为空'))
      }
    },
    validaterTopicAmount(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (parseInt(value) == 0) {
          return callback(new Error('题目数应>0'))
        }
        return callback()
      } else {
        return callback(new Error('题目数不能为空'))
      }
    },
    validaterQualifiedScore(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.totalScore)) {
          if (parseInt(value) > parseInt(this.msForm.totalScore)) {
            return callback(new Error('合格分数应<考试总分'))
          }
        }
        return callback()
      } else {
        return callback(new Error('合格分数不能为空'))
      }
    },
    validaterOrderIndex(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (!/^(?:\D*\d){3}\D*$/.test(value)) {
          return callback(new Error('请输入三位数字编号'))
        }
        return callback()
      } else {
        return callback(new Error('排序编号不能为空'))
      }
    },
    /**
     * 保存或更新
     */
    addOrModifyExamConfig() {
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.scoreMode) && this.msForm.scoreMode == 0 && this.$zwxBase.verifyIsBlank(this.msForm.choosedTopicList)) {
        this.$system.notify('错误', '请至少添加一条配置明细', 'error')
        return false
      }
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.scoreMode) && this.msForm.scoreMode == 2 && this.$zwxBase.verifyIsBlank(this.msForm.examConfigDetailExpList)) {
        this.$system.notify('错误', '请至少添加一条配置明细', 'error')
        return false
      }
      //计算总分
      if (this.msForm.scoreMode === 0) {
        var totalScore = 0
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.choosedTopicList)) {
          this.msForm.choosedTopicList.forEach(val => {
            //
            if (this.$zwxBase.verifyIsNotBlank(val.score)) {
              totalScore += parseFloat(val.score)
            }
          })
        }
        this.msForm.totalScore = totalScore + ''
      }
      //计算总分
      if (this.msForm.scoreMode === 2) {
        var totalScore = 0
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.examConfigDetailExpList)) {
          this.msForm.examConfigDetailExpList.forEach(val => {
            //
            if (this.$zwxBase.verifyIsNotBlank(val.topicScore) && this.$zwxBase.verifyIsNotBlank(val.topicAmount)) {
              totalScore = parseFloat(parseFloat(totalScore) + parseFloat(val.topicScore) * parseFloat(val.topicAmount)).toFixed(1)
            }
          })
        }
        this.msForm.totalScore = totalScore + ''
      }
      if (parseFloat(this.msForm.totalScore) >= 1000) {
        this.$system.notify('错误', '自定义配置考试总分不能超1000分', 'error')
        return false
      }
      let data = {
        ...this.msForm,
      }
      data.traineeType = this.msForm.roleType
      data.trainingType = this.msForm.trainType
      this.$refs['msForm'].validate(valid => {
        if (valid) {
          this.$system.postJson(
            this.api + '/training/platform/addOrModifyExamConfig-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', '保存成功', 'success')
                this.$router.push({ name: 'ExaminationManagement', params: { fresh: true } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        } else {
          return false
        }
      })
    },
    /**
     * 获取详情
     */
    getDetail(uuid) {
      let data = {
        uuid: uuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getExamConfig-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.uuid = data.configExp.uuid
            this.msForm.examName = data.configExp.examName
            if (this.$zwxBase.verifyIsNotBlank(data.configExp.examTime)) {
              this.msForm.examTime = data.configExp.examTime + ''
            }
            this.msForm.pointType = data.configExp.pointType

            this.msForm.qualifiedScore = data.configExp.qualifiedScore
            this.msForm.scoreMode = data.configExp.scoreMode
            this.msForm.topicMode = data.configExp.topicMode
            // this.msForm.topicType = data.configExp.topicType
            if (this.$zwxBase.verifyIsNotBlank(data.configExp.topicAmount)) {
              this.msForm.topicAmount = data.configExp.topicAmount + ''
            }
            this.msForm.topicScore = data.configExp.topicScore || ''
            this.msForm.examConfigDetailExpList = data.configExp.examConfigDetailExpList || []
            this.msForm.examConfigDetailExpList.forEach(item => {
              if (this.$zwxBase.verifyIsNotBlank(item.topicType)) {
                item.topicType = item.topicType + ''
              }
            })
            this.msForm.choosedTopicList = data.configExp.choosedTopicList || []
            // 无锡参数赋值------开始
            if (this.showFiled) {
              this.msForm.examType = data.configExp.examType
              this.msForm.roleType = data.configExp.traineeType
              this.dataList.forEach(item => {
                if (this.msForm.roleType == item.codeNo) {
                  this.trainTypeList = item.children
                }
              })
              this.msForm.trainType = data.configExp.trainingType
              this.msForm.examNoticeDetails = data.configExp.examNoticeDetails
              this.msForm.examNoticeTitle = data.configExp.examNoticeTitle
            }
            // 无锡参数赋值------结束
            this.msFormOld = JSON.stringify(this.msForm)
            this.$nextTick(() => {
              this.msForm.totalScore = data.configExp.totalScore
              this.$refs['msForm'].clearValidate()
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true)

        this.msFormOld = JSON.stringify(this.msForm)
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.getDetail(this.$route.params.row.uuid)
      }
    },
    //组件返回监听
    propEvent(somedata) {
      if (somedata) {
        let list = somedata.split(',')
        list.map((v, k) => {
          list[k] = this.simpleCodeMap[v]
        })
        // this.msForm.pointTypeName = list.join(',')
        this.$set(this.msForm, 'pointTypeName', list.join(','))
      } else {
        this.$set(this.msForm, 'pointTypeName', '')
      }
      this.$set(this.msForm, 'pointType', somedata)
    },
    /**
     * 培训类型数据源
     */
    getPointTypeList() {
      var list = []
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('4020', data => {
        list = data['4020']
        this.pointTypeList = list
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          this.simpleCodeList = this.manyListFul(list, '')
          list.map(item => {
            this.simpleCodeMap[item.codeNo] = item.codeName
          })
        }
      })
    },
    /**
     * 自定义 增行
     */
    addCustomConfig(row, i) {
      let count = {}
      this.msForm.examConfigDetailExpList.push(count)
    },
    deleteList(row) {
      this.msForm.examConfigDetailExpList.splice(row, 1)
      let totalScore = 0
      this.msForm.examConfigDetailExpList.forEach(item => {
        if (this.$zwxBase.verifyIsNotBlank(item.topicAmount) && this.$zwxBase.verifyIsNotBlank(item.topicScore)) totalScore += item.topicAmount * item.topicScore
      })
      this.msForm.totalScore = totalScore + ''
    },
    deleteChoosedTopicList(row) {
      this.msForm.choosedTopicList.splice(row, 1)
      let totalScore = 0
      this.msForm.choosedTopicList.forEach(item => {
        totalScore += item.score ? parseFloat(item.score) : 0
      })
      this.msForm.totalScore = totalScore + ''
    },
    /**
     * 打开添加课程弹框
     */
    showChooseTopicDialog() {
      this.$refs.chooseTopicDialogRef.show(true)
      this.queryTopicList(1)
      this.msForm.choosedTopicList.forEach(row => {
        this.$nextTick(function() {
          this.$refs.topicMultipleTable.toggleRowSelection(row, true)
        })
      })
    },

    /**
     * 分页查询题目列表
     */
    queryTopicList(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        topicContent: this.chooseTopicForm.topicContent,
        topicTypeArr: this.$zwxBase.verifyIsNotBlank(this.chooseTopicForm.topicTypeList) ? this.chooseTopicForm.topicTypeList.join(',') : null,
        // pointTypes: this.chooseTopicForm.pointTypes,
        ifEnableArr: 1,
        pointTypes: this.chooseTopicForm.topicType,
        currentPage: currentPage,
        pageSize: this.chooseTopicForm.pageSize,
      }
      this.$system.get(
        this.api + '/training/platform/getTrainingTopicList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.chooseTopicForm.topicList = data.trainingTopicList
            this.chooseTopicForm.total = data.trainingTopicCount
            this.chooseTopicForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 题型格式化
     */
    topicTypeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        switch (cellValue) {
          case 1:
            return '单选题'
          case 2:
            return '多选题'
          case 3:
            return '判断题'
          default:
        }
      }
      return '—'
    },

    /**
     * 题目选择回调
     */
    topicSelectionChange(selectList) {
      this.chooseTopicForm.choosedList = selectList
    },
    /**
     * 确认题目弹框
     */
    determineChooseTopicDialog() {
      let choosedTopicList = this.msForm.choosedTopicList //已选择题目
      let choosedList = this.chooseTopicForm.choosedList //确认选择题目
      choosedTopicList.forEach(item => {
        choosedList.forEach(choose => {
          if (item.rid == choose.rid) {
            this.$set(choose, 'score', item.score)
          }
        })
      })
      for (let i = 1; i <= choosedList.length; i++) {
        this.$set(choosedList[i - 1], 'orderIndex', i < 10 ? '00' + i : i < 100 ? '0' + i : i)
      }
      this.msForm.choosedTopicList = choosedList
      this.$refs.chooseTopicDialogRef.show(false)
    },
    /**
     * 取消题目弹框
     */
    cancelChooseTopicDialog() {
      this.$refs.chooseTopicDialogRef.show(false)
    },
    /**
     * 关闭题目弹框回调
     */
    closeChooseTopicDialog() {
      this.chooseTopicForm.currentPage = 1
      this.chooseTopicForm.pageSize = 7
      this.chooseTopicForm.topicContent = ''
      this.chooseTopicForm.pointTypes = ''
      this.chooseTopicForm.topicTypeList = []
      this.chooseTopicForm.topicList = []
      this.chooseTopicForm.total = 0
    },

    /**
     * 手动选择添加
     */
    addChooseManuallyConfig() {},
  },
}
</script>

<style lang="less" scoped>
/deep/ .choose-topic-dialog .el-dialog {
  width: 840px !important;
  min-width: 568px !important;
}

.choose-topic-dialog .choose-topic-body {
  height: 413px;
}
</style>

<style lang="less"></style>
