var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hazard-distribution-index hazard" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "hazardDistributionIndexRef",
          staticClass: "zwx-form edit-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _vm.existsDustFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsDustExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsDustExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "粉尘因素接触总人数： " +
                              _vm._s(_vm.msForm.dustContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsDustExpend,
                            expression: "existsDustExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.dustHazardList, function(childs, i) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.staffNumber) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "dustHazardList" + i + j,
                                              prop:
                                                "dustHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(child.checkNumber)
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "dustHazardList" + i + j,
                                              prop:
                                                "dustHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.overNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.existsChemicalFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsChemicalExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsChemicalExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "化学因素接触总人数： " +
                              _vm._s(_vm.msForm.chemicalContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsChemicalExpend,
                            expression: "existsChemicalExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.chemicalHazardList, function(
                          childs,
                          i
                        ) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "chemicalHazardList" + i + j,
                                              prop:
                                                "chemicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".staffNumber",
                                              rules: _vm.rules.staffNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.staffNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "chemicalHazardList" + i + j,
                                              prop:
                                                "chemicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.checkNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "chemicalHazardList" + i + j,
                                              prop:
                                                "chemicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.overNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.existsPhysicalFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsPhysicalExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsPhysicalExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "物理因素接触总人数： " +
                              _vm._s(_vm.msForm.physicalContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsPhysicalExpend,
                            expression: "existsPhysicalExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.physicalHazardList, function(
                          childs,
                          i
                        ) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "physicalHazardList" + i + j,
                                              prop:
                                                "physicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".staffNumber",
                                              rules: _vm.rules.staffNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.staffNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "physicalHazardList" + i + j,
                                              prop:
                                                "physicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.checkNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "physicalHazardList" + i + j,
                                              prop:
                                                "physicalHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(child.overNumber)
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.existsRadioactivityFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsRadioactivityExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsRadioactivityExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "放射危害因素接触总人数： " +
                              _vm._s(_vm.msForm.radioactivityContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsRadioactivityExpend,
                            expression: "existsRadioactivityExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.radioactivityHazardList, function(
                          childs,
                          i
                        ) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id:
                                                "radioactivityHazardList" +
                                                i +
                                                j,
                                              prop:
                                                "radioactivityHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".staffNumber",
                                              rules: _vm.rules.staffNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.staffNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id:
                                                "radioactivityHazardList" +
                                                i +
                                                j,
                                              prop:
                                                "radioactivityHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.checkNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id:
                                                "radioactivityHazardList" +
                                                i +
                                                j,
                                              prop:
                                                "radioactivityHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.overNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.existsBioticFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsBioticExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsBioticExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "生物因素接触总人数： " +
                              _vm._s(_vm.msForm.bioticContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsBioticExpend,
                            expression: "existsBioticExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.bioticHazardList, function(
                          childs,
                          i
                        ) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c("el-form-item", {
                                          staticStyle: {
                                            "margin-bottom": "0 !important",
                                            "margin-right": "0 !important"
                                          },
                                          attrs: {
                                            id: "bioticHazardList" + i + j,
                                            prop:
                                              "bioticHazardList." +
                                              i +
                                              "." +
                                              j +
                                              ".staffNumber",
                                            rules: _vm.rules.staffNumber
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "bioticHazardList" + i + j,
                                              prop:
                                                "bioticHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.checkNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "bioticHazardList" + i + j,
                                              prop:
                                                "bioticHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.overNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.existsOtherFlag
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "hazard-title",
                        staticStyle: { "border-bottom": "1px solid #e1e5f4" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          style: `transform:rotate(${
                            _vm.existsOtherExpend ? -90 : 0
                          }deg);transition: all .5s ease 0s;`,
                          on: {
                            click: function($event) {
                              return _vm.expand("existsOtherExpend")
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            "其他因素接触总人数： " +
                              _vm._s(_vm.msForm.otherContacts)
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("transition-group", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.existsOtherExpend,
                            expression: "existsOtherExpend"
                          }
                        ],
                        key: _vm.getKey(),
                        staticClass: "div-table"
                      },
                      [
                        _c("div", { staticClass: "div-table-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          ),
                          _c("div", { staticClass: "div-table-head-border" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "div-table-head-name-col flew-center",
                              staticStyle: { flex: "1" }
                            },
                            [_vm._v("接触危害因素")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("接触人数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("检测点数")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "div-table-head-name-col flew-center"
                            },
                            [_vm._v("超标点数")]
                          )
                        ]),
                        _vm._l(_vm.msForm.otherHazardList, function(childs, i) {
                          return [
                            _c(
                              "div",
                              { staticClass: "div-table-row" },
                              [
                                _vm._l(childs, function(child, j) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          "justify-content": "flex-start",
                                          flex: "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.hazardsName) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "otherHazardList" + i + j,
                                              prop:
                                                "otherHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".staffNumber",
                                              rules: _vm.rules.staffNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.staffNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "otherHazardList" + i + j,
                                              prop:
                                                "otherHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".checkNumber",
                                              rules: _vm.rules.checkNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.checkNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "div-table-td-num-col flew-center"
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0 !important",
                                              "margin-right": "0 !important"
                                            },
                                            attrs: {
                                              id: "otherHazardList" + i + j,
                                              prop:
                                                "otherHazardList." +
                                                i +
                                                "." +
                                                j +
                                                ".overNumber",
                                              rules: _vm.rules.overNumber
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(child.overNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    j === 0
                                      ? _c("div", {
                                          staticClass: "div-table-head-border"
                                        })
                                      : _vm._e(),
                                    childs.length == 1
                                      ? _c("div", {
                                          staticStyle: {
                                            width: "calc(50% - 5px)"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("demo", {
        directives: [
          { name: "show", rawName: "v-show", value: true, expression: "true" }
        ],
        ref: "hazardSelectDialog",
        attrs: {
          selectedFactors: _vm.factorSelectDialog.factors,
          title: _vm.factorSelectDialog.title,
          tableLabel: _vm.factorSelectDialog.dialogRoeName,
          hazardsSort: _vm.factorSelectDialog.hazardsSort
        },
        on: { submitFactor: _vm.confirmFactorDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }