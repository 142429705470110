<template>
  <div class="detection-report-info-detail">
    <div class="body">
      <!-- <employer-detail :employerUuid="employerUuid"></employer-detail> -->
      <!-- <template v-if="$route.params.reportType == 3">
        <div style="border-bottom: 1px solid #E6E6E6;height: 10px;"></div>
        <special-edition-detail v-if="specialDetectionReportExp" :basicExp="specialDetectionReportExp" />
      </template> -->
      <!-- <el-divider v-else class="base-divider" /> -->
      <el-tabs v-if="reportType==1">
       
          <detection-report-basic-detail :unitOptionList="unitOptionList" :employerUuid="employerUuid" :basicExp="detectionReportExp" v-if="detectionReportExp.reportType == 1" />
   
      </el-tabs>
      <el-tabs v-if="reportType==2" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息及检测结论" name="first">
          <detection-report-basic-detail :unitOptionList="unitOptionList" :employerUuid="employerUuid" :basicExp="detectionReportExp" v-if="detectionReportExp.reportType == 2" />
        </el-tab-pane>
        <el-tab-pane label="检测信息" name="second">
          <detection-report v-if="activeName=='second'" :reportHazardExpList="detectionReportExp.newReportWorkplaceExpList"></detection-report>
          <!-- <detection-report-basic-detail :employerUuid="employerUuid" :basicExp="detectionReportExp" v-if="detectionReportExp.reportType == 2" /> -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import DetectionReportBasicDetail from './detailComponents/DetectionReportBasicDetail.vue'
import DetectionReport from './detailComponents/DetectionReportHazardDetail.vue'
export default {
  name: 'DetectionReportInfoDetail',
  components: { DetectionReportBasicDetail,DetectionReport },
  data() {
    return {
      api: this.$store.state.api,
      rid: this.$route.params.rid,
      reportType:1,
      employerUuid: this.$route.params.employerUuid,
      activeName: 'first',
      specialDetectionReportExp: null,
      detectionReportExp: {
        reportAnnexExpList1: [],
        reportAnnexExpList2: [],
        reportHazardExpList: [],
      },
      unitOptionList:[]
    }
  },
  created() {},
  mounted() {
    this.getUnits()
    if (this.$route.params.reportType == 1) {
      this.getSpecialDetail(this.$route.params.uuid)
    } else {
      this.reportType=this.$route.params.reportType 
      this.detail(this.$route.params.uuid)
    }
  },
  activated() {},
  computed: {},
  watch: {
    detectionReportExp(newVal, oldVal) {
      this.dealReportHazardExpList(newVal)
      this.$forceUpdate()
    },
  },
  methods: {
    getUnits() {
      const data = {
        codeTypeCodes: '2002',
      }
      this.$system.get(
        this.api + '/systematic/getSimpleCodeListByCodeTypeCodesMap-0',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.unitOptionList = data.resultMap[2002].map(i => {
              return {
                label: i.codeName,
                value: i.codeNo,
              }
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    getSpecialDetail(uuid) {
      this.$emit('loading', true)
      let data = {
        uuid: uuid,
      }
      this.$system.post(
        this.api + '/workplace/detection/getDetectionReport-1',
        data,
        true,
        false,
        data => {
          if (data.type === '00') {
            this.detectionReportExp=data.resultInfo
            
            // this.specialDetectionReportExp = data.detectionReportExp || {}
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        this.error
      )
    },

    /**
     * 查询详情
     */
    detail(rid) {
      this.$emit('loading', true)
      let data = {
        uuid:rid,
      }
      
      this.$system.post(this.api + '/workplace/detection/getDetectionReport-1', data, true, true, this.detailSuccess, this.error)
    },
    detailSuccess(data) {
      if (data.type === '00') {
        this.detectionReportExp = data.resultInfo || {}
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
    handleClick() {},
    dealReportHazardExpList(newVal) {
      if (this.$zwxBase.verifyIsBlank(newVal)) {
        return
      }
      if (newVal.reportType == 2) {
        this.activeName = 'first'
      } 
      if (newVal.reportType == 1) {
        return
      }
      // console.log(newVal)
      // newReportWorkplaceExpList
      // for (let i = 0; i < newVal.reportHazardExpList.length; i++) {
      //   var reportHazard = newVal.reportHazardExpList[i]
      //   this.$set(reportHazard, 'codeNo', reportHazard.hazardsCode)
      //   for (let j = 0; j < reportHazard.reportJobExpList.length; j++) {
      //     var job = reportHazard.reportJobExpList[j]
      //     this.$set(job, 'placeData', [{ jobName: job.jobName, workplaceName: job.workplaceName }])
      //     var detectionItem = job.reportHazardsExpList.map(reportHazard => reportHazard.detectionItemCode)
      //     this.$set(job, 'detectionItem', detectionItem)
      //     for (let k = 0; k < job.reportHazardsExpList.length; k++) {
      //       var reportHazardsExp = job.reportHazardsExpList[k]
      //       this.$set(reportHazardsExp, 'itemCode', reportHazardsExp.detectionItemCode)
      //       this.$set(reportHazardsExp, 'itemName', reportHazardsExp.detectionItemName)
      //       //用于检测项目回显是否显示填写
      //       this.$set(reportHazardsExp, 'itemStdTwa', reportHazardsExp.tagTwa)
      //       this.$set(reportHazardsExp, 'itemStdStel', reportHazardsExp.tagStel)
      //       this.$set(reportHazardsExp, 'itemStdMac', reportHazardsExp.tagMe)
      //       this.$set(reportHazardsExp, 'judgementResult', reportHazardsExp.judgementResult ? 1 : 0)
      //     }
      //   }
      // }
      // this.detectionReportExp.reportHazardExpList = newVal.reportHazardExpList
    },
  },
}
</script>

<style lang="less" scoped>
.detection-report-info-detail {
  .body {
    // padding-left: 12px;
  }
  /deep/ .el-tabs__header {
    margin: unset !important;
    // border-top: 2px rgb(230, 230, 230) solid;
  }
  /deep/ .el-tabs__active-bar {
    margin-left: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #316cf5;
    line-height: 14px;
  }
  /deep/ .el-tabs__item {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  /deep/ .el-tabs__nav {
    padding-left: 30px;
  }
}
</style>

<style lang="less"></style>
