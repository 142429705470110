var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detection-report-info-detail" }, [
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm.reportType == 1
          ? _c(
              "el-tabs",
              [
                _vm.detectionReportExp.reportType == 1
                  ? _c("detection-report-basic-detail", {
                      attrs: {
                        unitOptionList: _vm.unitOptionList,
                        employerUuid: _vm.employerUuid,
                        basicExp: _vm.detectionReportExp
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.reportType == 2
          ? _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "基本信息及检测结论", name: "first" } },
                  [
                    _vm.detectionReportExp.reportType == 2
                      ? _c("detection-report-basic-detail", {
                          attrs: {
                            unitOptionList: _vm.unitOptionList,
                            employerUuid: _vm.employerUuid,
                            basicExp: _vm.detectionReportExp
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "检测信息", name: "second" } },
                  [
                    _vm.activeName == "second"
                      ? _c("detection-report", {
                          attrs: {
                            reportHazardExpList:
                              _vm.detectionReportExp.newReportWorkplaceExpList
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }