<template>
  <div class="detection-report-hazard-detail">
    <base-headline title="检测结果" />
    <div class="hazard" v-for="(item, index) in newreportHazardExpList" :key="index" style="margin-bottom: 10px;">
      <div class="hazard-title" style="border-bottom: 1px solid #e1e5f4;">
        <i class="el-icon-caret-bottom" :style="`transform:rotate(${item.ifExpand ? -90 : 0}deg);transition: all .5s ease 0s;`" @click="expand(item)"></i>
        <!-- <span>{{ item.hazardsName }}</span> -->
        <span>{{ item.workplaceName }}</span>
      </div>
     <transition-group>
        <div class="hazard post transition-box" v-show="!item.ifExpand" v-for="(val, key) in item.newDetectionReportJobExpList" :key="getKey(key)">
          <div class="hazard-title post-title">
            <i></i>
            <span>{{ val.jobName }}</span>
            <span style="color: #191919">{{ val.staffNumber }}人</span>
            <div class="splice"></div>
            <span style="color: #191919">每班接触时长{{ val.exposureHd }}(h/d)</span>
            <div class="splice"></div>
            <span style="color: #191919">每周接触天数{{ val.exposureDw }}(d/w)</span>
          </div>
          <el-table class="zwx-table" style="width: 100%;" :row-key="getRid()" :data="val.reportHazardExpList" border stripe>
            <el-table-column prop="hazardsName" label="职业病危害因素" :width="200" header-align="center" align="center"></el-table-column>
            <el-table-column prop="detectionItemName" label="检测项目" :width="200" header-align="center" align="center"></el-table-column>
            <el-table-column prop="checkPointName" label="检测点位" :width="150" header-align="center" align="center"></el-table-column>
            <el-table-column prop="valueTwa" :width="200" header-align="center" align="center">
              <template slot="header">
                C
                <sub>TWA</sub>
              </template>
              <template slot-scope="scope">
                {{ scope.row.tagTwa==2?'<':'' }} {{ scope.row.valueTwa!=null?scope.row.valueTwa:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="valueStel" :width="200" header-align="center" align="center">
              <template slot="header">
                C
                <sub>STE</sub>
              </template>
              <template slot-scope="scope">
               {{ scope.row.tagStel==2?'<':'' }}  {{ scope.row.valueStel!=null?scope.row.valueStel:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="valueMe" :width="200" header-align="center" align="center">
              <template slot="header">
                C
                <sub>ME</sub>
              </template>
              <template slot-scope="scope">
                {{ scope.row.tagMe==2?'<':'' }} {{ scope.row.valueMe!=null?scope.row.valueMe:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="valuePe" :width="200" header-align="center" align="center">
              <template slot="header">
                C
                <sub>PE</sub>
              </template>
              <template slot-scope="scope">
                {{ scope.row.tagPe==2?'<':'' }}   {{ scope.row.valuePe!=null?scope.row.valuePe:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="noiseLex" label="噪声等效声级" :width="160" header-align="center" align="center">
              <template slot-scope="scope">
               {{ scope.row.noiseLex!=null?scope.row.noiseLex:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="otherValue" label="其他因素" :width="160" header-align="center" align="center">
              <template slot-scope="scope">
               {{ scope.row.otherValue!=null?scope.row.otherValue:'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="judgementResult" label="判定结果" header-align="center" align="center">
              <template slot-scope="scope">
                <div class="zwx-circle-text zwx-circle-finish" v-if="scope.row.judgementResult == 0">超标</div>
                <div class="zwx-circle-text zwx-circle-complete" v-if="scope.row.judgementResult == 1">合格</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </transition-group> 
    </div>
  </div>
</template>
<script>
export default {
  name: 'DetectionReportHazardDetail',
  props: {
    reportHazardExpList: { type: Array, default: () => [] },
  },
  data() {
    return {
      newreportHazardExpList:[]
    }
  },
  watch: {
    reportHazardExpList(newVal, oldVal) {
      if (this.$zwxBase.verifyIsNotBlank(newVal)) {
        this.$forceUpdate()
      }
    },
  },
  created() {},
  mounted() {
   this.newreportHazardExpList= this.reportHazardExpList.map(x=>{
      
      x.newDetectionReportJobExpList.map(y=>{
        var reportHazardExpList=[]
        y.newReportHazardExpList.map(z=>{
          
          z.newDetectionReportHazardsExpList.map(i=>{
            i.hazardsName=z.hazardsName
            reportHazardExpList.push(i)
          })
          return z
          
        })
        y.reportHazardExpList=reportHazardExpList
        return y
      })
      
      return x
    })
  },
  activated() {},
  computed: {},
  methods: {
    getRid() {
      return 'rid' + new Date().getTime() + Math.floor(Math.random() * 101)
    },
    getKey(key) {
      return key + new Date().getTime()
    },
    expand(item) {
      if (item.newDetectionReportJobExpList.length == 0) return
      this.$set(item, 'ifExpand', !item.ifExpand)
    },
  },
}
</script>

<style lang="less" scoped>
.hazard {
  // margin-top: 10px;

  &-title {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    background: #f6f8fb;
    box-shadow: 0px 1px 0px 0px #e1e5f4 inset, 0px 0px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset;
    font-size: 14px;
    font-weight: 500;
    color: #505b89;
    & i:first-child {
      cursor: pointer;
      color: #b0bfe6;
    }
    & i:nth-child(n + 2) {
      margin-right: 15px;
      color: #0037c7;
      cursor: pointer;
    }
    span {
      margin-right: 20px;
      padding: 0 10px;
    }
  }
}
</style>

<style lang="less"></style>
