var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "examination_management_edit",
      staticStyle: { "padding-bottom": "20px" }
    },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.msFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "考试名称：",
                        prop: "examName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "537px !important" },
                        attrs: {
                          placeholder: "请输入考试名称",
                          clearable: "",
                          maxlength: "50"
                        },
                        model: {
                          value: _vm.msForm.examName,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "examName", $$v)
                          },
                          expression: "msForm.examName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "考试时长：",
                        prop: "examTime"
                      }
                    },
                    [
                      _c("amount-input", {
                        attrs: {
                          width: "80px",
                          maxlength: "9",
                          "if-unit": "",
                          "unit-text": "分钟",
                          numbers: 9,
                          digits: 0,
                          clearable: ""
                        },
                        model: {
                          value: _vm.msForm.examTime,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "examTime", $$v)
                          },
                          expression: "msForm.examTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "考试总分：",
                        prop: "totalScore"
                      }
                    },
                    [
                      _c("amount-input", {
                        attrs: {
                          width: "80px",
                          maxlength: "9",
                          disabled: "",
                          "if-unit": "",
                          "unit-text": "分",
                          numbers: 3,
                          digits: 1,
                          clearable: ""
                        },
                        model: {
                          value: _vm.msForm.totalScore,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "totalScore", $$v)
                          },
                          expression: "msForm.totalScore"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "合格分数：",
                        prop: "qualifiedScore"
                      }
                    },
                    [
                      _c("amount-input", {
                        attrs: {
                          width: "80px",
                          maxlength: "9",
                          "if-unit": "",
                          "unit-text": "分",
                          numbers: 3,
                          digits: 1,
                          clearable: ""
                        },
                        model: {
                          value: _vm.msForm.qualifiedScore,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "qualifiedScore", $$v)
                          },
                          expression: "msForm.qualifiedScore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showFiled
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "考试类型：",
                            "label-width": "110px",
                            prop: "examType"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              model: {
                                value: _vm.msForm.examType,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "examType", $$v)
                                },
                                expression: "msForm.examType"
                              }
                            },
                            _vm._l(_vm.examTypeList, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.codeNo,
                                  staticClass: "zwx-radio",
                                  attrs: { label: item.codeNo }
                                },
                                [_vm._v(_vm._s(item.codeName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFiled
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "学员类型：",
                            "label-width": "110px",
                            prop: "roleType"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              on: { change: _vm.roleTypeChange },
                              model: {
                                value: _vm.msForm.roleType,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "roleType", $$v)
                                },
                                expression: "msForm.roleType"
                              }
                            },
                            _vm._l(_vm.traineeTypeList, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.codeNo,
                                  staticClass: "zwx-radio",
                                  attrs: { label: item.codeNo }
                                },
                                [_vm._v(_vm._s(item.codeName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFiled && _vm.msForm.roleType
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "培训类型：",
                            "label-width": "110px",
                            prop: "trainType"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              model: {
                                value: _vm.msForm.trainType,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "trainType", $$v)
                                },
                                expression: "msForm.trainType"
                              }
                            },
                            _vm._l(_vm.trainTypeList, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.codeNo,
                                  staticClass: "zwx-radio",
                                  attrs: { label: item.codeNo }
                                },
                                [_vm._v(_vm._s(item.codeName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFiled && _vm.ifShowOff
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "考试须知标题：",
                            "label-width": "110px",
                            prop: "examNoticeTitle"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input",
                            staticStyle: { width: "537px !important" },
                            attrs: {
                              placeholder: "请输入考试须知标题",
                              clearable: "",
                              maxlength: "50"
                            },
                            model: {
                              value: _vm.msForm.examNoticeTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "examNoticeTitle", $$v)
                              },
                              expression: "msForm.examNoticeTitle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFiled && _vm.ifShowOff
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "考试须知内容：",
                            "label-width": "110px",
                            prop: "examNoticeDetails"
                          }
                        },
                        [
                          _c("kind-editor", {
                            attrs: {
                              id: "editor_id",
                              height: "500px",
                              width: "800px",
                              imageSizeInfo: _vm.kindEditorParam.imageSizeInfo,
                              imageSize: _vm.kindEditorParam.imageSize
                            },
                            on: { "on-content-change": _vm.onContentChange },
                            model: {
                              value: _vm.msForm.examNoticeDetails,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "examNoticeDetails", $$v)
                              },
                              expression: "msForm.examNoticeDetails"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分配方式：",
                        "label-width": "110px",
                        prop: "scoreMode"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "zwx-radio-group",
                          staticStyle: { "min-width": "68px!important" },
                          on: { change: _vm.scoreModeChange },
                          model: {
                            value: _vm.msForm.scoreMode,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "scoreMode", $$v)
                            },
                            expression: "msForm.scoreMode"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 0 } },
                            [_vm._v("手动选择")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 1 } },
                            [_vm._v("随机抽取")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 2 } },
                            [_vm._v("规则抽取")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.msForm.scoreMode == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "抽取规则：",
                            "label-width": "90px",
                            prop: "topicMode"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              on: { change: _vm.topicModeChange },
                              model: {
                                value: _vm.msForm.topicMode,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "topicMode", $$v)
                                },
                                expression: "msForm.topicMode"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "zwx-radio",
                                  attrs: { label: 1 }
                                },
                                [_vm._v("按类别")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "zwx-radio",
                                  attrs: { label: 2 }
                                },
                                [_vm._v("按题型")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.msForm.scoreMode == 1
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "110px",
                            label: "题目总数：",
                            prop: "topicAmount"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input",
                            staticStyle: { width: "100px !important" },
                            attrs: {
                              placeholder: "请输入",
                              clearable: "",
                              maxlength: "9"
                            },
                            on: {
                              input: function($event) {
                                return _vm.$validate.number(
                                  _vm.$data["msForm"],
                                  "topicAmount",
                                  9,
                                  0,
                                  0
                                )
                              }
                            },
                            model: {
                              value: _vm.msForm.topicAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "topicAmount", $$v)
                              },
                              expression: "msForm.topicAmount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "90px",
                            label: "每题分值：",
                            prop: "topicScore"
                          }
                        },
                        [
                          _c("amount-input", {
                            attrs: {
                              width: "80px",
                              maxlength: "9",
                              disabled: _vm.msForm.scoreMode != 1,
                              "if-unit": "",
                              "unit-text": "分",
                              numbers: 3,
                              digits: 1,
                              clearable: ""
                            },
                            model: {
                              value: _vm.msForm.topicScore,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "topicScore", $$v)
                              },
                              expression: "msForm.topicScore"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.scoreMode == 1
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _vm.msForm.scoreMode == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "110px",
                                label: "题目类别：",
                                prop: "pointType"
                              }
                            },
                            [
                              _c("cascader-options-multi", {
                                ref: "mscCascader",
                                attrs: {
                                  options: _vm.simpleCodeList,
                                  dataNameFiled: "codeName",
                                  dataCodeFiled: "codeNo",
                                  dataUpCodeFiled: "children",
                                  codeLevelNo: "codeLevelNo",
                                  width: "537px",
                                  showFullTag: false,
                                  collapsetags: true
                                },
                                on: { propEvent: _vm.propEvent },
                                model: {
                                  value: _vm.msForm.pointType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.msForm, "pointType", $$v)
                                  },
                                  expression: "msForm.pointType"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.scoreMode == 0
                ? _c(
                    "base-headline",
                    { staticClass: "no-bottom", attrs: { title: "考题配置" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-icontext-28",
                          attrs: { icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.showChooseTopicDialog()
                            }
                          }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.scoreMode === 0
                ? _c(
                    "el-table",
                    {
                      ref: "chooseTopicMultipleTable",
                      staticClass: "zwx-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "row-key": "rid",
                        data: _vm.msForm.choosedTopicList,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "130",
                          prop: "score",
                          label: "排序编号",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "choosedTopicList." +
                                          scope.$index +
                                          ".orderIndex",
                                        rules: _vm.msFormRules.orderIndex
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input cc-input",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "min-width": "68px!important"
                                        },
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          maxlength: "3",
                                          placeholder: "请输入"
                                        },
                                        on: { change: _vm.orderIndexChange },
                                        model: {
                                          value: scope.row.orderIndex,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "orderIndex",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.orderIndex"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3893282860
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "600",
                          prop: "topicContent",
                          label: "题目标题",
                          "header-align": "center",
                          align: "left"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "topicType",
                          label: "题型",
                          "header-align": "center",
                          align: "center",
                          formatter: _vm.topicTypeFormatter
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "130",
                          prop: "score",
                          label: "题目分值",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "choosedTopicList." +
                                          scope.$index +
                                          ".score",
                                        rules: _vm.msFormRules.score
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input cc-input",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "min-width": "68px!important"
                                        },
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "请输入"
                                        },
                                        on: {
                                          change: _vm.chooseTopicScoreChange,
                                          input: function($event) {
                                            return _vm.$validate.number(
                                              _vm.$data["msForm"][
                                                "choosedTopicList"
                                              ][scope.$index],
                                              "score",
                                              3,
                                              1,
                                              0
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.score,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "score",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.score"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1281698480
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "min-width": 110,
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "zwx-button zwx-button-text-26",
                                      class: "zwx-button-danger",
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteChoosedTopicList(
                                            scope.$index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3213799737
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.scoreMode == 2
                ? _c(
                    "base-headline",
                    {
                      staticClass: "no-bottom",
                      attrs: { title: "自定义配置" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-icontext-28",
                          attrs: { icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.addCustomConfig()
                            }
                          }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.scoreMode === 2
                ? _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticClass: "zwx-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "row-key": "rid",
                        data: _vm.msForm.examConfigDetailExpList,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "250",
                          prop: "topicType",
                          label:
                            _vm.msForm.topicMode == 1 ? "题目类别" : "题目类型",
                          "header-align": "center",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm.msForm.topicMode == 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "examConfigDetailExpList." +
                                              scope.$index +
                                              ".pointType",
                                            rules: _vm.msFormRules.pointType
                                          }
                                        },
                                        [
                                          _c("cascader-options-single", {
                                            ref: "mscCascader",
                                            attrs: {
                                              options: _vm.simpleCodeList,
                                              dataNameFiled: "codeName",
                                              dataCodeFiled: "codeNo",
                                              dataUpCodeFiled: "children",
                                              width: "260px"
                                            },
                                            model: {
                                              value: scope.row.pointType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "pointType",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.pointType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.msForm.topicMode == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "examConfigDetailExpList." +
                                              scope.$index +
                                              ".topicType",
                                            rules: _vm.msFormRules.topicType
                                          }
                                        },
                                        [
                                          _c("cascader-options-single", {
                                            ref: "mscCascader",
                                            attrs: {
                                              options: _vm.topicTypeOptions,
                                              dataNameFiled: "codeName",
                                              dataCodeFiled: "codeNo",
                                              dataUpCodeFiled: "children",
                                              width: "260px"
                                            },
                                            model: {
                                              value: scope.row.topicType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "topicType",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.topicType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3525709810
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "200",
                          prop: "topicAmount",
                          label: "题目数",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "examConfigDetailExpList." +
                                          scope.$index +
                                          ".topicAmount",
                                        rules: _vm.msFormRules.topicAmount
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input cc-input",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "min-width": "68px!important"
                                        },
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "请输入",
                                          maxlength: "11"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.positiveInteger(
                                              scope.row,
                                              "topicAmount"
                                            )
                                          },
                                          change: _vm.scoreChange
                                        },
                                        model: {
                                          value: scope.row.topicAmount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "topicAmount",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.topicAmount"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          490347181
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "200",
                          prop: "topicScore",
                          label: "题目分值",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "examConfigDetailExpList." +
                                          scope.$index +
                                          ".topicScore",
                                        rules: _vm.msFormRules.topicScore
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input cc-input",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "min-width": "68px!important"
                                        },
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "请输入"
                                        },
                                        on: {
                                          change: _vm.scoreChange,
                                          input: function($event) {
                                            return _vm.$validate.number(
                                              _vm.$data["msForm"][
                                                "examConfigDetailExpList"
                                              ][scope.$index],
                                              "topicScore",
                                              3,
                                              1,
                                              0
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.topicScore,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "topicScore",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.topicScore"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1806411948
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "min-width": 110,
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "zwx-button zwx-button-text-26",
                                      class: "zwx-button-danger",
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteList(scope.$index)
                                        }
                                      }
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2171807745
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "chooseTopicDialogRef",
          staticClass: "choose-topic-dialog",
          attrs: {
            title: "选择题目",
            pagination: true,
            currentPage: _vm.chooseTopicForm.currentPage,
            pageSize: _vm.chooseTopicForm.pageSize,
            total: _vm.chooseTopicForm.total
          },
          on: {
            determine: _vm.determineChooseTopicDialog,
            cancel: _vm.cancelChooseTopicDialog,
            close: _vm.closeChooseTopicDialog,
            currentPage: _vm.queryTopicList
          }
        },
        [
          _c(
            "div",
            { staticClass: "choose-topic-body" },
            [
              _c(
                "el-form",
                {
                  key: "chooseTopicForm",
                  ref: "chooseTopicFormRef",
                  staticClass: "zwx-form",
                  attrs: {
                    model: _vm.chooseTopicForm,
                    "label-position": "right"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "self-adapting-condition" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "题目标题：" } },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input",
                            staticStyle: { width: "310px !important" },
                            attrs: { placeholder: "", clearable: "" },
                            model: {
                              value: _vm.chooseTopicForm.topicContent,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.chooseTopicForm,
                                  "topicContent",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "chooseTopicForm.topicContent"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "题目类别：", prop: "pointTypes" } },
                        [
                          _c("cascader-options-multi", {
                            ref: "mscCascader",
                            attrs: {
                              options: _vm.simpleCodeList,
                              dataNameFiled: "codeName",
                              dataCodeFiled: "codeNo",
                              dataUpCodeFiled: "children",
                              codeLevelNo: "codeLevelNo",
                              width: "315px",
                              showFullTag: false,
                              collapsetags: true
                            },
                            on: { propEvent: _vm.propEvent },
                            model: {
                              value: _vm.chooseTopicForm.topicType,
                              callback: function($$v) {
                                _vm.$set(_vm.chooseTopicForm, "topicType", $$v)
                              },
                              expression: "chooseTopicForm.topicType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "题型：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              staticClass: "zwx-checkbox-group",
                              model: {
                                value: _vm.chooseTopicForm.topicTypeList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.chooseTopicForm,
                                    "topicTypeList",
                                    $$v
                                  )
                                },
                                expression: "chooseTopicForm.topicTypeList"
                              }
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "zwx-checkbox",
                                  attrs: { label: 1 }
                                },
                                [_vm._v("单选题")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "zwx-checkbox",
                                  attrs: { label: 2 }
                                },
                                [_vm._v("多选题")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "zwx-checkbox",
                                  attrs: { label: 3 }
                                },
                                [_vm._v("判断题")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-icontext-28",
                          attrs: { icon: "el-icon-search" },
                          on: {
                            click: function($event) {
                              return _vm.queryTopicList(1)
                            }
                          }
                        },
                        [_vm._v("检索")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-table",
                {
                  ref: "topicMultipleTable",
                  staticClass: "zwx-table choose-topic-table",
                  attrs: {
                    "row-key": "rid",
                    data: _vm.chooseTopicForm.topicList,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "light",
                    "row-key": _vm.rowKey
                  },
                  on: { "selection-change": _vm.topicSelectionChange }
                },
                [
                  _c("el-table-column", {
                    key: "selection",
                    attrs: {
                      type: "selection",
                      width: "55",
                      "header-align": "center",
                      align: "center",
                      "reserve-selection": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pointTypeName",
                      label: "题目类别",
                      width: "200",
                      "header-align": "center",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "topicContent",
                      label: "题目标题",
                      "header-align": "center",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "topicType",
                      label: "类型",
                      width: "80",
                      "header-align": "center",
                      align: "center",
                      formatter: _vm.topicTypeFormatter
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.addOrModifyExamConfig }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }