var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-basic-detail" },
    [
      _c(
        "div",
        [
          _c("base-headline", {
            staticClass: "topTitle",
            attrs: { title: "基本信息" }
          }),
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-descriptions",
                    { staticClass: "basic-descriptions", attrs: { column: 1 } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: {
                            label: "单位名称",
                            "content-class-name": "tech-value-class"
                          }
                        },
                        [_vm._v(_vm._s(_vm.basicExp.employerName))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "行政区划" }
                        },
                        [_vm._v(_vm._s(_vm.basicExp.bizFullName))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "报告日期" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.basicExp.reportDate.replace("00:00:00", "")
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "报告附件" }
                        },
                        [
                          _vm.basicExp.reportAnnexExpList1 &&
                          _vm.basicExp.reportAnnexExpList1.length != 0
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.basicExp.reportAnnexExpList1,
                                  function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.fileName,
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c("div", {
                                          class: _vm.imgClass(item.fileName)
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ms-folder-content pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.openFilePreview(
                                                  item.fileName,
                                                  item.filePath
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.fileName) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "basic-descriptions last-basic-descriptions",
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { column: 1 } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "社会信用代码" }
                        },
                        [_vm._v(_vm._s(_vm.basicExp.employerCreditCode))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "检测类型" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.basicExp.reportType == 1
                                  ? "定期检测"
                                  : _vm.basicExp.reportType == 2
                                  ? "现状评价"
                                  : _vm.basicExp.reportType == 3
                                  ? "监督检测"
                                  : "-"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          staticClass: "info-item",
                          attrs: { label: "报告编号" }
                        },
                        [_vm._v(_vm._s(_vm.basicExp.reportCode))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("base-headline", { attrs: { title: "主要产品" } }, [
        _c(
          "span",
          { staticStyle: { "margin-left": "20px", color: "#000000" } },
          [
            _vm._v(
              "军工等涉密产品：" +
                _vm._s(_vm.basicExp.ifClassifiedUnit ? "是" : "否")
            )
          ]
        )
      ]),
      !_vm.basicExp.ifClassifiedUnit
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.basicExp.detectionProductExpList,
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "index", label: "序号", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3056706777
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "header-align": "center",
                      align: "center",
                      prop: "productName"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "年产量",
                      "header-align": "center",
                      align: "center",
                      prop: "annualOutput"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      prop: "unit",
                      "header-align": "center",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.basicExp.reportType == 1
        ? _c(
            "div",
            [
              _c("base-headline", {
                staticClass: "topTitle",
                attrs: { title: "检测信息" }
              }),
              _c("div", [_c("HazardDistribution", { ref: "hazard" })], 1)
            ],
            1
          )
        : _vm._e(),
      _vm.basicExp.reportType == 2
        ? _c(
            "div",
            [
              _c("base-headline", {
                staticClass: "topTitle",
                attrs: { title: "检测结论" }
              }),
              _c(
                "div",
                {
                  staticClass: "basic-detail-page",
                  staticStyle: { margin: "10px 30px" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          _vm.basicExp.detectionConclusion,
                          ""
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("base-headline", { attrs: { title: "建议" } }),
              _c(
                "div",
                {
                  staticClass: "basic-detail-page",
                  staticStyle: { margin: "10px 30px" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          _vm.basicExp.reportSuggest,
                          ""
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }