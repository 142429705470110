var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-hazard-detail" },
    [
      _c("base-headline", { attrs: { title: "检测结果" } }),
      _vm._l(_vm.newreportHazardExpList, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "hazard",
            staticStyle: { "margin-bottom": "10px" }
          },
          [
            _c(
              "div",
              {
                staticClass: "hazard-title",
                staticStyle: { "border-bottom": "1px solid #e1e5f4" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-caret-bottom",
                  style: `transform:rotate(${
                    item.ifExpand ? -90 : 0
                  }deg);transition: all .5s ease 0s;`,
                  on: {
                    click: function($event) {
                      return _vm.expand(item)
                    }
                  }
                }),
                _c("span", [_vm._v(_vm._s(item.workplaceName))])
              ]
            ),
            _c(
              "transition-group",
              _vm._l(item.newDetectionReportJobExpList, function(val, key) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.ifExpand,
                        expression: "!item.ifExpand"
                      }
                    ],
                    key: _vm.getKey(key),
                    staticClass: "hazard post transition-box"
                  },
                  [
                    _c("div", { staticClass: "hazard-title post-title" }, [
                      _c("i"),
                      _c("span", [_vm._v(_vm._s(val.jobName))]),
                      _c("span", { staticStyle: { color: "#191919" } }, [
                        _vm._v(_vm._s(val.staffNumber) + "人")
                      ]),
                      _c("div", { staticClass: "splice" }),
                      _c("span", { staticStyle: { color: "#191919" } }, [
                        _vm._v(
                          "每班接触时长" + _vm._s(val.exposureHd) + "(h/d)"
                        )
                      ]),
                      _c("div", { staticClass: "splice" }),
                      _c("span", { staticStyle: { color: "#191919" } }, [
                        _vm._v(
                          "每周接触天数" + _vm._s(val.exposureDw) + "(d/w)"
                        )
                      ])
                    ]),
                    _c(
                      "el-table",
                      {
                        staticClass: "zwx-table",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "row-key": _vm.getRid(),
                          data: val.reportHazardExpList,
                          border: "",
                          stripe: ""
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "hazardsName",
                            label: "职业病危害因素",
                            width: 200,
                            "header-align": "center",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "detectionItemName",
                            label: "检测项目",
                            width: 200,
                            "header-align": "center",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "checkPointName",
                            label: "检测点位",
                            width: 150,
                            "header-align": "center",
                            align: "center"
                          }
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "valueTwa",
                              width: 200,
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.tagTwa == 2 ? "<" : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            scope.row.valueTwa != null
                                              ? scope.row.valueTwa
                                              : "--"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _vm._v(" C "),
                              _c("sub", [_vm._v("TWA")])
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "valueStel",
                              width: 200,
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.tagStel == 2 ? "<" : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            scope.row.valueStel != null
                                              ? scope.row.valueStel
                                              : "--"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _vm._v(" C "),
                              _c("sub", [_vm._v("STE")])
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "valueMe",
                              width: 200,
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.tagMe == 2 ? "<" : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            scope.row.valueMe != null
                                              ? scope.row.valueMe
                                              : "--"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _vm._v(" C "),
                              _c("sub", [_vm._v("ME")])
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "valuePe",
                              width: 200,
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.tagPe == 2 ? "<" : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            scope.row.valuePe != null
                                              ? scope.row.valuePe
                                              : "--"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _vm._v(" C "),
                              _c("sub", [_vm._v("PE")])
                            ])
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "noiseLex",
                            label: "噪声等效声级",
                            width: 160,
                            "header-align": "center",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.noiseLex != null
                                            ? scope.row.noiseLex
                                            : "--"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "otherValue",
                            label: "其他因素",
                            width: 160,
                            "header-align": "center",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.otherValue != null
                                            ? scope.row.otherValue
                                            : "--"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "judgementResult",
                            label: "判定结果",
                            "header-align": "center",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.judgementResult == 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "zwx-circle-text zwx-circle-finish"
                                          },
                                          [_vm._v("超标")]
                                        )
                                      : _vm._e(),
                                    scope.row.judgementResult == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "zwx-circle-text zwx-circle-complete"
                                          },
                                          [_vm._v("合格")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }