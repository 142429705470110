<template>
  <div class="detection-report-basic-detail">
    <div>
      <base-headline title="基本信息" class="topTitle" />
      <el-row style="padding:20px;">
        <el-col :span="8">
          <el-descriptions class="basic-descriptions" :column="1">
            <el-descriptions-item class="info-item" label="单位名称" content-class-name="tech-value-class">{{ basicExp.employerName }}</el-descriptions-item>
            <el-descriptions-item class="info-item" label="行政区划">{{ basicExp.bizFullName }}</el-descriptions-item>
            <el-descriptions-item class="info-item" label="报告日期">{{ basicExp.reportDate.replace('00:00:00', '') }}</el-descriptions-item>
            <el-descriptions-item class="info-item" label="报告附件">
              <div v-if="basicExp.reportAnnexExpList1 && basicExp.reportAnnexExpList1.length != 0">
                <div style="display: flex;align-items: center;" v-for="(item, index) in basicExp.reportAnnexExpList1" :key="item.fileName">
                  <div :class="imgClass(item.fileName)"></div>
                  <div class="ms-folder-content pointer" @click="openFilePreview(item.fileName, item.filePath)">
                    {{ item.fileName }}
                  </div>
                </div>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-col>

        <el-col :span="8" class="basic-descriptions last-basic-descriptions">
          <el-descriptions :column="1">
            <el-descriptions-item class="info-item" label="社会信用代码">{{ basicExp.employerCreditCode }}</el-descriptions-item>

            <el-descriptions-item class="info-item" label="检测类型">
              {{ basicExp.reportType == 1 ? '定期检测' : basicExp.reportType == 2 ? '现状评价' : basicExp.reportType == 3 ? '监督检测' : '-' }}
            </el-descriptions-item>
            <el-descriptions-item class="info-item" label="报告编号">{{ basicExp.reportCode }}</el-descriptions-item>
           
       
          </el-descriptions>
        </el-col>
      </el-row>
    </div>
    <base-headline title="主要产品" >
    <span style="margin-left: 20px;color:#000000;">军工等涉密产品：{{ basicExp.ifClassifiedUnit ? '是' : '否' }}</span>
       <!-- <el-descriptions-item class="info-item" label="军工等涉密产品：">
          {{ basicExp.ifClassifiedUnit ? '是' : '否' }}
        </el-descriptions-item> -->
      </base-headline>

    <!-- <div class="basic-descriptions" style="padding: 20px 12px 8px;">
      <el-descriptions :column="1">
        <el-descriptions-item class="info-item" label="军工等涉密产品：">
          {{ basicExp.ifClassifiedUnit ? '是' : '否' }}
        </el-descriptions-item>
      </el-descriptions>
    </div> -->
    <div  v-if="!basicExp.ifClassifiedUnit">
      <el-table class="zwx-table" style="width: 100%;" :data="basicExp.detectionProductExpList" border stripe>
        <el-table-column prop="index" label="序号" width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="产品名称" header-align="center" align="center" prop="productName"></el-table-column>
        <el-table-column label="年产量" header-align="center" align="center" prop="annualOutput"></el-table-column>
        <el-table-column label="单位" prop="unit" header-align="center" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="basicExp.reportType == 1">
      <base-headline title="检测信息" class="topTitle"/>
      <div>
        <HazardDistribution  ref="hazard"></HazardDistribution>
      </div>
    </div>
    <div v-if="basicExp.reportType == 2">
      <base-headline title="检测结论" class="topTitle" />
      <div class="basic-detail-page" style="margin: 10px 30px;">
        {{ formatNullDataByNoTable(basicExp.detectionConclusion, '') }}
      </div>
      <base-headline title="建议" />
      <div class="basic-detail-page" style="margin: 10px 30px;">
        {{ formatNullDataByNoTable(basicExp.reportSuggest, '') }}
      </div>
    </div>
  </div>
</template>
<script>
import DetectionReportHazardDetail from './DetectionReportHazardDetail.vue'
import HazardDistribution from '../components/HazardDistDetail.vue'
export default {
  name: 'DetectionReportBasicDetail',
  components: { DetectionReportHazardDetail, HazardDistribution },
  props: {
    basicExp: {
      type: Object,
      default: {
        reportAnnexExpList1: [],
        reportAnnexExpList2: [],
      },
    },
    unitOptionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      detectionTypeDict: ['--', '定期检测', '现状评价', '监督检测', '评价检测'],
      annexList1: [],
      annexList2: [],
    }
  },
  created() {},
  mounted() {
    if (this.basicExp.reportType == 1) {
      this.$refs.hazard.setList(this.basicExp)
    }
    // this.dealAnnexList(this.basicExp)
    console.log(this.basicExp)
  },
  watch: {
    basicExp(newVal, oldVal) {
      if (this.$zwxBase.verifyIsNotBlank(newVal)) {
        this.dealAnnexList(newVal)
        this.$forceUpdate()
      }
    },
  },
  activated() {},
  computed: {},
  methods: {
    imgClass(fileName) {
      return fileName && (fileName.lastIndexOf('jpg') >= 0 || fileName.lastIndexOf('pdf') >= 0 || fileName.lastIndexOf('png') >= 0) ? 'basic-img-div-' + fileName.substring(fileName.lastIndexOf('.') + 1) : ''
    },
    dealAnnexList(newVal) {
      if (this.$zwxBase.verifyIsBlank(newVal)) {
        return
      }
      this.annexList1 = []
      newVal.reportAnnexExpList1.forEach(annex => {
        this.annexList1.push({
          fileName: annex.fileName,
          filePath: annex.filePath,
          check: false,
          fileIconShow: 'colorful chis-icon-colorful-pdf',
        })
      })
      // this.annexList2 = []
      // newVal.reportAnnexExpList2.forEach(annex => {
      //   this.annexList2.push({
      //     fileName: annex.fileName,
      //     filePath: annex.filePath,
      //     check: false,
      //     fileIconShow: 'colorful chis-icon-colorful-pdf',
      //   })
      // })
    },
    openFilePreview(previewFileList) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: previewFileList,
            index: 0,
          },
        },
        '*'
      )
    },
    formatNullDataByNoTable(cellValue, suffixName) {
      if (window.vm.$zwxBase.verifyIsBlank(cellValue)) {
        return '--'
      } else {
        return cellValue + suffixName
      }
    },
    /**
     * 日期格式化
     */
    dateStrFormatter(cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
      return '--'
    },
  },
}
</script>

<style lang="less" scoped>
.detection-report-basic-detail {
  .top {
    height: 30px;
    line-height: 30px;
  }
  .width-270 {
    width: 270px;
  }
  .attachments {
    display: flex;
    flex-flow: column;
    span {
      color: #2a50d7;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
    }
  }
}
.info-item::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffaf92;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
.basic-descriptions /deep/ .el-descriptions-item__label::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffaf92;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
.basic-detail-page {
  margin: 0 30px;
  .title {
    margin: 2px 0px 8px 0;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-family: Harmony Medium;
    font-weight: 500;
    text-align: left;
    color: #191919;
    display: flex;
    align-items: center;
  }
}

.topTitle {
  /deep/ .zwx-head-title {
    border-top: none !important;
  }
}
.basic-img-div-pdf {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_pdf.svg') left 0px no-repeat;
}
.basic-img-div-jpg {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
.basic-img-div-png {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
</style>

<style lang="less"></style>
