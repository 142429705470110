import { render, staticRenderFns } from "./DetectionReportBasicInfoDetail.vue?vue&type=template&id=2e6533a7&scoped=true"
import script from "./DetectionReportBasicInfoDetail.vue?vue&type=script&lang=js"
export * from "./DetectionReportBasicInfoDetail.vue?vue&type=script&lang=js"
import style0 from "./DetectionReportBasicInfoDetail.vue?vue&type=style&index=0&id=2e6533a7&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6533a7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e6533a7')) {
      api.createRecord('2e6533a7', component.options)
    } else {
      api.reload('2e6533a7', component.options)
    }
    module.hot.accept("./DetectionReportBasicInfoDetail.vue?vue&type=template&id=2e6533a7&scoped=true", function () {
      api.rerender('2e6533a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/agency/workplace_detection/DetectionReportBasicInfoDetail.vue"
export default component.exports